import * as styles from "./index.css"

interface Props extends React.ComponentPropsWithoutRef<"span"> {
  width?: number
  height?: number
  currentColor?: boolean
  children: React.ReactNode
}

export function IconBase({
  width = 24,
  height = 24,
  currentColor = true,
  children,
  className = "",
  ...props
}: Props) {
  return (
    <span
      className={`${className} ${styles.icon} ${
        currentColor ? styles.currentColor : ""
      }`}
      style={{ width, height }}
      {...props}
    >
      {children}
    </span>
  )
}
