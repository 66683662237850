"use client"

import { assignInlineVars } from "@vanilla-extract/dynamic"
import { clsx } from "clsx"
import * as styles from "./index.css"

interface Props extends React.ComponentPropsWithoutRef<"span"> {
  text: string
  line?: number
}

export function LineClamp({ text, line = 2, className = "", ...props }: Props) {
  return (
    <span
      className={clsx(styles.clamp, className)}
      style={assignInlineVars({ [styles.line]: `${line}` })}
      {...props}
    >
      {text}
    </span>
  )
}
