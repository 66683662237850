import { getStorageData, setStorageData } from "@/app/_utils/localStorage"
import { CSRF_TOKEN } from "../_data/local_storage_key"

// 使用側でエラーのstatus等を参照したいため、Errorを継承したクラスを作成
export class ResponseError extends Error {
  response: Response

  constructor(message: string, res: Response) {
    super(message)
    this.response = res
  }
}

// TODO: エラー処理
export async function fetcher<Response>(
  key: string | URL | Request,
  init?: RequestInit
) {
  const csrfToken = getStorageData<string>(CSRF_TOKEN)
  const res = await fetch(key, {
    ...init,
    headers: {
      "Content-Type": "application/json",
      "x-csrf-token": csrfToken ?? ""
    }
  })

  // 200以外
  if (!res.ok) {
    const error = new ResponseError(
      "An error occurred while fetching the data.",
      res
    )
    throw error
  }

  if (res.status === 204) {
    return {} as Response // 型を使いやすい形で維持したいため。本来はvoidを返す。
  }

  return res.json() as Response
}

export const fetchWithCache = async <Response>(
  key: string | URL | Request,
  cacheKey: string,
  expiryInMinutes: number
) => {
  const cache = getStorageData<Response>(cacheKey)
  if (cache !== undefined) {
    return cache
  }

  const data = await fetcher<Response>(key)

  if (data !== undefined) {
    // expiryInMinutes分間キャッシュ
    setStorageData(cacheKey, data, expiryInMinutes)
  }

  return data
}
