"use client"

import { clsx } from "clsx"
import Lottie from "lottie-react"
import circularAnimationBase from "./IndicatorCircularBase.json"
import * as styles from "./index.css"
import circularAnimationMain from "./indicatorCircularMain.json"

type Type = "main" | "base"
type Size = "medium" | "large"

interface Props {
  type?: Type
  size?: Size
  className?: string
}

export function IndicatorCircular({
  type = "main",
  size = "medium",
  className
}: Props) {
  return (
    <Lottie
      animationData={
        type === "main" ? circularAnimationMain : circularAnimationBase
      }
      className={clsx(
        size === "medium" ? styles.medium : styles.large,
        className
      )}
    />
  )
}
