export const SHOWROOM_TARGET_USER_KEY = "showroom_target_user"
export const NEWS_BADGE_KEY = "news_badge"
export const FLOATING_BANNER_DISPLAY_KEY = "floating_banner_display"
export const ITEM_REVIEW_DRAFT_KEY = "item_review_draft"
export const ITEM_LIST_DISPLAY_TYPE_KEY = "item_list_display_type"
export const PHOTO_LIST_DISPLAY_TYPE_KEY = "photo_list_display_type"
export const CART_ITEMS_COUNT_KEY = "cart_items_count"
export const ANNOUNCEMENT_BANNER_KEY = "announcement_banner"
export const CSRF_TOKEN = "csrf_token"
export const VIEW_ITEM_HISTORY_KEY = "view_item_history"
export const FIRST_TIME_DEFAULT_OPEN_SHEET_KEY = "first_time_default_open_sheet"
