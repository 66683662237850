import { differenceInMinutes } from "date-fns"

export function getStorageData<T>(key: string): T | undefined {
  const ls =
    "localStorage" in window && window.localStorage ? window.localStorage : null
  if (ls === null) {
    return
  }

  const cache = ls.getItem(key)
  if (cache === null) {
    return
  }
  const parsedCache = JSON.parse(cache)
  // 有効期限切れかどうかチェック
  const timeNow = new Date()
  const cachedDate = new Date(parsedCache.created)

  if (differenceInMinutes(timeNow, cachedDate) < parsedCache.expiry) {
    // 有効期限内ならキャッシュを返す
    return parsedCache.value
  }

  // 有効期限切れなら削除しておく
  ls.removeItem(key)
}

export function setStorageData(
  key: string,
  value: unknown,
  expiryInMinutes = 60 * 24 * 7
) {
  const ls =
    "localStorage" in window && window.localStorage ? window.localStorage : null
  if (ls === null) {
    return
  }

  const data = {
    created: new Date().getTime(),
    value,
    expiry: expiryInMinutes
  }
  ls.setItem(key, JSON.stringify(data))
}

export function deleteStorageData(key: string) {
  const ls =
    "localStorage" in window && window.localStorage ? window.localStorage : null
  if (ls === null) {
    return
  }

  const cache = ls.getItem(key)
  if (cache === null) {
    return
  }
  ls.removeItem(key)
}
