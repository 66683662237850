import type { AccountSetting, AnnouncementBanner } from "@/app/_types"
import { atom } from "jotai"

export const appLinkBaseUrlAtom = atom(
  process.env.NEXT_PUBLIC_APP_LINK_BASE_URL
)
appLinkBaseUrlAtom.debugLabel = "appLinkBaseUrlAtom"

export const isAppUA = atom(false)
isAppUA.debugLabel = "isAppUA"

export const mediaType = {
  desktop: "desktop",
  tablet: "tablet",
  mobile: "mobile"
} as const

type MediaType = (typeof mediaType)[keyof typeof mediaType]

const mediaAtom = atom<MediaType>(mediaType.desktop)
mediaAtom.debugLabel = "mediaAtom"

export const media = atom(
  get => {
    const type = get(mediaAtom)
    return {
      isDesktop: type === mediaType.desktop,
      isTablet: type === mediaType.tablet,
      isMobile: type === mediaType.mobile
    }
  },
  (_, set, update: MediaType) => {
    set(mediaAtom, update)
  }
)
media.debugLabel = "media"

const accountAtom = atom<AccountSetting | null | undefined>(undefined)
accountAtom.debugLabel = "accountAtom"

export const account = atom(
  get => get(accountAtom),
  (_, set, update: AccountSetting | null) => {
    set(accountAtom, update)
  }
)
account.debugLabel = "account"

export const isLoggedIn = atom(get => {
  const account = get(accountAtom)
  return account !== undefined && account !== null
})
isLoggedIn.debugLabel = "isLoggedIn"

export const userId = atom(get => {
  const account = get(accountAtom)
  if (account === undefined || account === null) {
    return null
  }
  return account.id
})
userId.debugLabel = "userId"

export const currentPointAtom = atom(0)
currentPointAtom.debugLabel = "currentPointAtom"

export const cartItemsCountAtom = atom(0)
cartItemsCountAtom.debugLabel = "cartItemsCountAtom"

export const newsUnreadCountAtom = atom(0)
newsUnreadCountAtom.debugLabel = "newsUnreadCountAtom"

export const announcementBannerAtom = atom<AnnouncementBanner | null>(null)
announcementBannerAtom.debugLabel = "announcementBannerAtom"

export const isOverlayAdVisibleAtom = atom<boolean>(false)

// お気に入りアイテムのIDを管理
export const wantedItemsAtom = atom<Record<number, boolean>>({})
wantedItemsAtom.debugLabel = "wantedItemsAtom"
